@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer components {
  .nav-link {
    @apply text-lg text-black;
  }

  .nav-link:hover {
    @apply text-red-500;
  }

  .nav-link.active {
    @apply text-red-500 font-medium;
  }
}
